// @import (less) "../../node_modules/semantic-ui-css/semantic.min.css";
@import (less) "../../node_modules/normalize.css/normalize.css";
@import (less) "../../node_modules/react-table-6/react-table.css";
@import (less) "../../node_modules/react-datepicker/dist/react-datepicker.css";
@import (inline) "../../node_modules/ag-grid-community/styles/ag-grid.css";
@import (inline) "../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import "./_variables";
@import "./components/_navigation";
@import "./components/_fixedMenu";
@import "./components/_layout";
@import "./components/_accounts";
@import "./components/_reconciliation";
@import "./components/_sidebar";
@import "./components/_envBanner";
@import "./components/_dateTime";
@import "./components/_progress";
@import "./components/_field";
@import "./overrides/_button";
@import "./overrides/_card";
@import "./overrides/_input";
@import "./overrides/_reactTable";
@import "./components/_memberSettings";
@import "./overrides/_modalTable";
@import "./overrides/_segment";
@import "./overrides/_react-datetime";
@import "./overrides/_modal";
@import "./overrides/_message";
@import "./components/_taskLog";
@import "./components/_wizardForm";
@import "./components/_detailsHeader";
@import "./components/_table";
@import "./components/_label";
@import "./components/_userSettings";
@import "./components/_riskTable";
@import "./components/_header";
@import "./components/_referralCode";
@import "./components/_marginRates";
@import "./components/_reports.less";

.ui.modal.transition.visible.active {
  max-height: 99%;
}

.ui.horizontal.segments.equal.width > .ui.segment {
  flex-grow: 1;
  width: 0;
}

@font-face {
  font-family: "Apercu";
  src: url("../../assets/fonts/apercu-regular.woff") format("woff"),
    url("../../assets/fonts/apercu-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Apercu";
  src: url("../../assets/fonts/apercu-bold.woff") format("woff"),
    url("../../assets/fonts/apercu-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Apercu";
  src: url("../../assets/fonts/apercu-italic.woff") format("woff"),
    url("../../assets/fonts/apercu-italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Apercu Mono";
  src: url("../../assets/fonts/apercu-mono-regular.woff") format("woff"),
    url("../../assets/fonts/apercu-mono-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.mono {
  font-family: "Apercu Mono";
  input,
  *:not(.icon) {
    font-family: "Apercu Mono";
  }
}
.pre {
  white-space: pre-wrap;
}
.italic {
  font-style: italic;
}

.bold {
  font-weight: 800;
}

// Style everything that isn't an icon with our official font
// This might change later. It's very much a heavy-handed solution.
body *:not(.icon):not(.mono):not([class^='ag-']) {
  font-family: "Apercu";
}

// Make an exception for table cells with split spans as children
td.mono > span {
  font-family: "Apercu Mono" !important;
}

body {
  height: 100%;
  margin: 0px;
  /*removes default style*/
  overflow: hidden;
  box-sizing: border-box;
}

.crypto-icon-USD::after,
.crypto-icon-usd::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/usd.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-BTC::after,
.crypto-icon-btc::after,
.crypto-icon-TBTC::after,
.crypto-icon-tbtc::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/btc.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-LTC::after,
.crypto-icon-ltc::after,
.crypto-icon-TLTC::after,
.crypto-icon-tltc::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/ltc.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-BCH::after,
.crypto-icon-bch::after,
.crypto-icon-TBCH::after,
.crypto-icon-tbch::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/bch.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-ETH::after,
.crypto-icon-eth::after,
.crypto-icon-TETH::after,
.crypto-icon-teth::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/eth.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-xrp::after,
.crypto-icon-XRP::after,
.crypto-icon-TXRP::after,
.crypto-icon-txrp::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/xrp.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-usdc::after,
.crypto-icon-USDC::after,
.crypto-icon-TEST::after,
.crypto-icon-test::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/usdc.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-aave::after,
.crypto-icon-AAVE::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/aave.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-link::after,
.crypto-icon-LINK::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: "";
  background: url("../../node_modules/cryptocurrency-icons/svg/color/link.svg") no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.bank-icon {
  background-color: #fff;
  color: @color-primary-4;
  border: none !important;
}

.icon-sm::after {
  width: @icon-sm;
  height: @icon-sm;
}

.icon-md::after {
  width: @icon-md;
  height: @icon-md;
}

.icon-lg::after {
  width: @icon-lg;
  height: @icon-lg;
}

.icon-hg::after {
  width: @icon-hg;
  height: @icon-hg;
}

.grey {
  color: @lightGrey;
}

.darkGrey {
  color: @darkGrey;
}

.negative-value {
  color: #cc1122 !important;
}

.borderless {
  border-color: transparent !important;
}

.no-wrap {
  white-space: nowrap;
}

.text-link {
  color: #4183c4;
  cursor: pointer;
  &:hover {
    color: #1e70bf;
  }
}

.text-center-marginTop-30 {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.text-right-marginBottom-10 {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
}

.iconDatePicker {
  display: inline-block !important;
  margin-left: 7px;
  .rdtOpen {
    .rdtPicker {
      right: 0 !important;
    }
  }
}

.hr-vertical {
  height: 15px;
  width: 1px;
  border-width: 0;
  color: #bcbdbd;
  background-color: #d4d4d5;
  margin: 0 0 0 10px;
}

.marginRateMainTab {
  width: 50%;
  margin-left: 5px;
}

.marginRateMainTabP {
  margin-bottom: 6px;
  margin-top: 6px;
}

.marginRateText {
  min-width: 85px;
  display: inline-block;
}

.alignRight {
  width: 50%;
  text-align: right;
}

.fullScreen{
  width: 80%!important;
}